// pages/404.tsx
import React from "react";
import Layout from "../components/layout/Layout/Layout";
import Head from "next/head";
import Header from "../components/layout/Header/Header";
import { formatTitle } from "../utils/helper";
import Link from "next/link";

const Custom404: React.FC = () => {
  return (
    <Layout>
      <Head>
        <title>{formatTitle("Not found")}</title>
      </Head>
      <Header />
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-xl font-bold">404 - Page Not Found</h1>
        <p className="text-base">
          The page you requested could not be found. Use your browser&apos;s
          Back button to navigate to the page you have prevously come from.
        </p>
        <Link href="/" className="text-blue-700 underline text-base">
          Or just go back to the home page
        </Link>
      </div>
    </Layout>
  );
};

export default Custom404;
